@use '../../colors.scss' as col;

html, body {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    background-color: col.$bg-primary;
    color: col.$text;
    font-family: sans-serif;
    scrollbar-color: col.$bg-tertiary col.$bg-primary;
}

textarea {
    border: none;
    background-color: col.$bg-card;
    border-radius: 0.25em;
    color: col.$text;
}

.app {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.app-pane {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.convos-pane {
    display: flex;
    flex-direction: column;
    width: 20em;
    flex: 0 0 auto;
    background-color: col.$bg-secondary;
}

.messages-pane {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
}

.command-box-pane {
    margin: 0.5em;
    display: flex;
}

.characters-pane {
    background-color: col.$bg-secondary;
    width: 20em;
    flex: 0 0 auto;
}