@use './colors.scss' as col;

input[type=text], input[type=number] {
    color: col.$text;
    background-color: col.$bg-tertiary;
    border: none;
    padding: 0.5em;
    border-radius: 0.25em;

    &:focus {
        outline: 1px solid col.$accent;
    }
}

button {
    background-color: col.$accent-dark;
    border: none;
    border-radius: 0.25em;
    color: col.$text;
    padding: 0.5em 1em;
    cursor: pointer;

    &:hover {
        background-color: col.$accent;
    }

    &:active {
        background-color: col.$accent-darker;
    }

    transition: background-color 0.125s ease;
}