@use '../../colors.scss' as col;

.message {
    display: table-row;
    position: relative;

    &:hover {
        background-color: rgba(255, 255, 255, 0.025);

        & .buttons {
            display: flex;
        }
    }

    & > * {
        display: table-cell;
    }
}

.contents {
    outline: none;
    width: 100%;
    white-space: pre-wrap;
}

.author {
    font-weight: bold;
    flex: 0 0 auto;
    white-space: nowrap;
    text-align: right;
    padding: 0.25em 0.5em 0.25em 0.5em;
    cursor: pointer;
    border-radius: 0.25em;

    &:hover {
        background-color: rgba(255, 255, 255, 0.125);
    }
}

.buttons {
    display: none;
    background-color: col.$bg-card;
    border-radius: 0.25em;
    padding: 0.25em;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
}

.message-edit-wrapper {
    width: 100%;
    display: flex;
}

.message-edit-area {
    flex: 1 1 auto;
    resize: vertical;
    box-sizing: border-box;
    margin: 0.25em 0.5em 0.25em 0;
}