@use '../../colors.scss' as col;

.modal-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    animation-duration: 0.125s;
    animation-name: fade;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-positioner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 2;
}

.modal {
    pointer-events: auto;
    overflow: auto;

    animation-duration: 0.125s;
    animation-name: popup;
    animation-timing-function: cubic-bezier(.3,1.25,.6,1);

    background-color: col.$bg-primary;
    border-radius: 0.25em;
    padding: 1em;
}

@keyframes popup {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}
