.icon {
    width: 24px;
    height: 24px;
    opacity: 0.5;

    background-repeat: no-repeat;
    background-origin: content-box;
}

.icon-button {
    cursor: pointer;

    &:hover {
        opacity: 0.75;
        cursor: pointer;
    }

    &.active {
        opacity: 1;
    }

    &.disabled {
        opacity: 0.175;
        cursor: not-allowed;
    }

    transition: opacity 0.125s ease;
}

@mixin def-icons($names) {
    @each $name in $names {
        .#{$name} {
            background-image: url('../../assets/icons/#{$name}.svg');
        }
    }
}

@include def-icons(
    delete
    edit
    export
    insert-above
    load
    save
)
